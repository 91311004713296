
import { Component, Vue } from 'vue-property-decorator';

// import components
import UserView from '@/components/user/UserView.vue';
import UserCategory from '@/components/user/UserCategory.vue';

@Component({
    components: {
        UserView,
        UserCategory
    },
})
export default class UserPage extends Vue {};
