import { render, staticRenderFns } from "./UserCategory.vue?vue&type=template&id=9ff73020&scoped=true&"
import script from "./UserCategory.vue?vue&type=script&lang=ts&"
export * from "./UserCategory.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ff73020",
  null
  
)

export default component.exports