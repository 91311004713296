
import { adGroupTypeReadAll } from '@/apiClient/adGroupTypeEndpoints';
import { campaignTypeReadAll } from '@/apiClient/campaignTypeEndpoints';
import { categoryReadAll } from '@/apiClient/categoryEndpoints';
import { customerReadAllPretty } from '@/apiClient/customerEndpoints';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { authGetMyCategories } from "../../apiClient/authEndpoints";

// import API
@Component
export default class UserCategory extends Vue {

    private categorySelected: number = 0;
    private mustChange: boolean = false;

    // MOUNTED
    public async mounted() {
        console.log("mounted()");

        await authGetMyCategories();

        let allDataPromises = [];
        // allDataPromises.push();
        allDataPromises.push(campaignTypeReadAll());
        allDataPromises.push(categoryReadAll());
        allDataPromises.push(customerReadAllPretty());
        allDataPromises.push(adGroupTypeReadAll());
        // @ts-ignore
        await Promise.all(allDataPromises);
    };

    public async setActiveCategory() {
        this.$store.commit("changeActiveCategoryId", this.categorySelected);
        console.log(this.categorySelected);
        this.toggleChange();


        let allDataPromises = [];
        // allDataPromises.push();
        allDataPromises.push(campaignTypeReadAll());
        allDataPromises.push(categoryReadAll());
        allDataPromises.push(customerReadAllPretty());
        allDataPromises.push(adGroupTypeReadAll());
        // @ts-ignore
        await Promise.all(allDataPromises);
    }

    public toggleChange() {
        this.mustChange = !this.mustChange;
    }

    public getCategory() {
        for (let category of this.$store.state.categoryCache.categoryList) {
            if (category.id === this.$store.state.activeCategoryId) {
                return category.name;
            }
        }
        return "ALL";
    }

};
