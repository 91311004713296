// import store
import store from "../store/index";

const authGetMyCategories = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/auth/getmycategories`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "nekeyto-category": `${store.state.activeCategoryId}`
            },
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            store.commit("setMyCategoriesCache", jsonResponse.data);
            if (typeof(store.state.activeCategoryId) !== "number") {
                store.commit("changeActiveCategoryId", jsonResponse.data[0].id);
            }
        }
        else {
            store.commit("setMyCategoriesCache", []);
            store.commit("changeActiveCategoryId", null);
        }
    }
    catch (error) {
        store.commit("setMyCategoriesCache", []);
        store.commit("changeActiveCategoryId", null);
    }
};

export {
    authGetMyCategories
}